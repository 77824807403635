@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --screen-xsm: 23.125rem;
  --screen-sm: 36rem;
  --screen-md: 48rem;
  --screen-nav: 54.375rem;
  --screen-xlnav: 69.38rem;
  --screen-lg: 62rem;
  --screen-xl: 78rem;
  --screen-max: 100rem;
  --screen-max-inner: calc(var(--screen-max) + (var(--spacing-inset-x)) * 2);
  --font-nhu: neue-haas-unica, sans-serif;
  --font-md: freight-display-pro, serif;
  --font-gotham: proxima-nova, sans-serif;
  --font-stint: 'Stint Ultra Expanded', cursive;
  --font-archer: ff-ernestine-pro, serif;
  --font-pragmatica: pragmatica, sans-serif;
  --color-bg: #ffff;
  --color-white: #ffff;
  --color-black: #000;
  --color-overlay-bg: rgba(255, 255, 255, 0.9);
  --color-text: #281a0b;
  --color-gold: #8e713e;
  --color-gold-on-gold-light-wcag: #876b3b;
  --color-gold-light: #b7965c;
  --color-gold-lightest: #d9d5ce;
  --color-blue-grey: #5f6368;
  --color-gold-black: #281a0b;
  --color-gold-white: #f8f6f0;
  --color-gold-white-line: #edeae3;
  --color-gold-white-dark: #cfcbc3;
  --color-input-bg: #f4f5f6;
  --color-input-text: #000;
  --color-input-placeholder: #575766;
  --color-grey: #e9ebed;
  /* --color-grey: #d9dcdf; */
  --color-grey-wcag: #575766;
  --color-grey-gold: #d8d8d8;
  --color-dark-grey: #323335;
  --color-darker-grey: #1e1e1f;
  --color-clock-grey: #a6a39e;
  --color-danger: #c20114;
  --color-black-bean: #471600;
  --color-seal-brown: #591c00;
  --spacing-inset-x: 2rem;
  --spacing-inset-y: calc(var(--spacing-inset-x) * 0.68);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --nav-height: 92px;
}

@screen nav {
  :root {
    /* --nav-height: 162px; */
    --nav-height: 92px;
  }
}

@screen xlnav {
  :root {
    --nav-height: 68px;
  }
}

@screen md {
  :root {
    --spacing-inset-x: 4rem;
  }
}

@screen max {
  :root {
    --spacing-inset-x: 8rem;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: monospace;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  background-color: var(--color-bg);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#__next > main {
  flex: 1 0 auto;
}
